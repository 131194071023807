import React from "react";

import HtmlHead from '../../components/html-head'

import styles from './component.module.less'

export default (props) => {

  const locale = props.locale || 'de';

  const {aside, main} = (props.children || {});

  return (
      <div className={styles.page}>
        <HtmlHead
            locale={locale}
            localeLinks={props.localeLinks}
            title={props.title}
            robots={props.robots}
            canonical={props.canonical}
            seo={props.seo}
        />

        <div className={styles.layout}>
          <aside className={styles.aside}>
            <div className={styles.inner}>
              <div className={styles.txt}>
                <a className={styles.logo} href={'/' + locale + '/'}>Golden Gates</a>
                {props.asideTextHtml &&
                  <div className={styles.text} dangerouslySetInnerHTML={{__html: props.asideTextHtml}} />
                }
                {aside}
              </div>
            </div>
          </aside>

          <main className={styles.content}>
            {!main && props.children}
            {main && main}
          </main>
        </div>
      </div>
  );
}
